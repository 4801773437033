<template>
  <div class="mine-page">
    <el-table
      class="data-table"
      :data="tableData"
      border
      :row-class-name="tableRowClassName">
      <el-table-column
        label="DATE"
        prop="createTime"
        width="110">
      </el-table-column>
      <el-table-column
        label="Success"
        prop="success"
        :formatter="formatNumber"
        align="right" width="140">
      </el-table-column>
      <el-table-column
        label="Fail"
        prop="fail"
        :formatter="formatNumber"
        align="right">
      </el-table-column>
      <el-table-column
        label="Plan Type"
        prop="planType"
        align="center"
        width="100">
      </el-table-column>
      <el-table-column
        label="Used Flow"
        prop="flow"
        :formatter="formatNumber"
        align="right">
      </el-table-column>
      <el-table-column
        label="Unit"
        prop="unit"
        align="center">
      </el-table-column>
    </el-table>
    <el-pagination
      class="layout-pag"
      :layout="pagination.layout"
      :current-page.sync="pagination.currentPage"
      :page-size="pagination.pageSize"
      :page-sizes="pagination.pageSizes"
      :total="pagination.total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange">
    </el-pagination>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { Pagination } from '@/mixins/pagination'
import { getApplyLog } from '@/apis/mine'


export default {
  mixins: [ Pagination ],
  components: {},
  props: {},
  data () {
    return {
      tableData: []
    }
  },
  computed: {},
  watch: {},
  methods: {
    ...mapMutations(['setLoading']),
    getList() {
      this.setLoading({
        state: true,
        text: ''
      })
      getApplyLog({
        page: this.pagination.currentPage,
        total: this.pagination.pageSize
      }, (res) => {
        // console.log(res)
        this.tableData = res
        this.pagination.total = res.length
        this.setLoading({
          state: false,
          text: ''
        })
      }, (err) => {
        // console.log(err)
        this.setLoading({
          state: false,
          text: ''
        })
      })
    },
    tableRowClassName({row, rowIndex}) {
      // console.log(rowIndex % 2)
      if (rowIndex % 2 !== 0) {
        return 'warning-row';
      }
      return '';
    },
    formatNumber(row, column, cellValue) {
      // 使用正则表达式添加千位分隔符
      return String(cellValue).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  },
  created () {
    this.handleSearch()
  },
  mounted () {},
  destroyed () {}
}
</script>
